import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Navbar extends Component {
  handleMobileNavToggle = () => {
    const mobileNav = document.getElementById('mobilenav');
    if (mobileNav.classList.contains('show')) {
      mobileNav.classList.remove('show');
    }
  };

  render() {
    return (
      <React.Fragment>
      <header className="header-one">
        <div className="main-menu">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-2 col-lg-2 d-flex col-4">
                <Link className="navbar-brand logo" to='/'>
                  <img src={require("../../assets/img/logo2.png")} alt="fethiye çocuk diş hekimi" />
                </Link>
              </div>
              <div className="col-lg-10 col-md-9 d-none d-lg-block text-lg-right">
                <nav id="responsive-menu" className="menu-style-one">
                  <ul className="menu-items">
                    <li><Link to='/' onClick={this.handleMobileNavToggle}>anasayfa</Link></li>
                    <li><Link to='/Page/DentistDetails' onClick={this.handleMobileNavToggle}>Uzman Dt. Nermin Ismayilova</Link></li>
                    <li><Link to='/Page/ServiceDetails' onClick={this.handleMobileNavToggle}>Tedavilerimiz</Link></li>
                    <li><Link to='/Page/Contact' onClick={this.handleMobileNavToggle}>İletişim</Link></li>
                  </ul>
                </nav>
              </div>
              <div className="col-md-9 col-sm-7 col-6 d-block d-lg-none mobid">
                <nav className="navbar navbar-expand-lg text-right navbar-light mobile-nav">
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#mobilenav"
                  >
                    <span className="fal fa-bars" />
                  </button>
                </nav>
              </div>
              <div className="collapse navbar-collapse mobile-menu" id="mobilenav">
                <ul className="navbar-nav mr-auto">
                  <li className="nav-item active">
                    <Link className="nav-link" to='/' onClick={this.handleMobileNavToggle}>Anasayfa</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to='/Page/DentistDetails' onClick={this.handleMobileNavToggle}>Hakkimizda</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to='/Page/ServiceDetails' onClick={this.handleMobileNavToggle}>Tedavilerimiz</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to='/Page/Contact' onClick={this.handleMobileNavToggle}>İletişim</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
      </React.Fragment>
    );
  }
}

export default Navbar;
