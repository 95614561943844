import React, { Component } from 'react'
import { Link } from 'react-router-dom';

class Promo extends Component {
    render() {
        return (
            <section className="appointment-promo-wrapper section-bg text-white">
                <div className="appointment-promo-shape">
                    <img src={require("../assets/img/shape/plusr.png")} alt="Fethiye Çocuk Diş Hekimi" className="shape app1" />
                    <img src={require("../assets/img/shape/plusr.png")} alt="Fethiye Çocuk Diş Hekimi" className="shape app2" />
                    <img src={require("../assets/img/shape/bluef.png")} alt="Fethiye Çocuk Diş Hekimi" className="shape app3" />
                    <img src={require("../assets/img/shape/bluef.png")} alt="Fethiye Çocuk Diş Hekimi" className="shape app4" />
                </div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12 col-lg-6  col-12">
                            <div className="appointment-promo-content appointment-mobile">
                                <h3>Randevu talep edin</h3>
                                <Link to="tel:+902526128844" className="theme-btn">Randevu Al</Link>
                            </div>
                            <div className="appointment-promo-content appointment-desktop">
                                <h3>Randevu talep edin</h3>
                                <Link to="../page/contact" className="theme-btn">Randevu Al</Link>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-6 col-12">
                            <div className="promo-right-banner text-center">
                                <img src={require("../assets/img/doctor-nars.png")} alt="Fethiye Çocuk Diş Hekimi" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Promo
