import React, { Component } from 'react'
import HeroOne from './Hero/HeroOne'
import AboutTwo from './About/AboutTwo'
import Promo from './Promo'
import TreatmentHero from './Service/TreatmentHero'

class HomeOne extends Component {
    render() {
        return (
            <React.Fragment>
                <HeroOne />
                <AboutTwo />
                <TreatmentHero />
                <Promo />
            </React.Fragment>
        )
    }
}

export default HomeOne