import React, { Component } from 'react'


class DentistDetails extends Component {
    render() {
        var bg = require ('../../assets/img/dentist-profile-img.jpg');

        return (
            <React.Fragment>

                <section className="dentist-details-wrap section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-7 col-lg-8 col-12">
                                <div className="single-dentist-details">
                                    <h2>Uzman Dt. Nermin Ismayilova</h2>
                                    <p>Uzman Dt. Nermin Ismayilova, kariyerine Azerbaycan, Tıp Üniversitesi Diş Hekimliği Fakültesi'nde başladı. Almanya'nın Greifswald Üniversitesi'nde Çocuk Diş Hekimliği Yüksek Lisans eğitimini tamamladıktan sonra, Türkiye'de Akdeniz Üniversitesi'nde Çocuk Diş Hekimliği uzmanlık eğitimini aldı.</p>
                                    <p>Fethiye'de bir özel klinikte hizmet veren Uzman Dt. Nermin Ismayilova, çocukların rahat hissedebileceği bir ortamda, modern teknolojileri kullanarak kaliteli diş sağlığı hizmetleri sunmaktadır. </p>
                                    <div className="dentist-award-membership">
                                        <h3>Başarı ve Sertifikaları</h3>
                                        <li>Diş Hekimliği Fakültesi - Tip Üniversitesi, Azerbaycan</li>
                                        <li>Çocuk Diş Hekimliği Yüksek Lisans eğitimi - Greifswald Üniversitesi, Almanya</li>
                                        <li>Çocuk Diş Hekimliği Uzmanlik eğitimi - Akdeniz Üniversitesi, Türkiye</li>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5 col-lg-4 col-12">
                                <div className="dentist-profile-details">
                                    <div className="profile-img bg-cover mb-35" style={{ backgroundImage: "url(" + bg + ")" }}  >
                                    </div>
                                    <p>Adı: <strong>Nermin Ismayilova</strong></p>
                                    <p>Uzmanlık alanı: <strong>Pedodonti</strong></p>
                                    <p>Telefon: <strong> (0252) 612 88 44</strong></p>
                                    <p>GSM: <strong>+90 501 344 88 44</strong></p>
                                    <div className="dentist-social-link">
                                        <a href="https://www.instagram.com/fethiyecocukdishekimi/"><i className="fab fa-instagram" /></a>
                                        <a href=".#"><i className="fab fa-twitter" /></a>
                                        <a href=".#"><i className="fab fa-linkedin-in" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
               
            </React.Fragment>
        )
    }
}

export default DentistDetails
