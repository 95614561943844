import React, { Component } from 'react'
import { Link } from 'react-router-dom';

class HeroOne extends Component {
    render() {
        return (
            <section className="hero-slider-wrapper home-one-slider">
                <div className="single-hero-slide text-white hero-slide1">
                    <div className="hero-shape">
                        <img src={require ("../../assets/img/shape/aab.png") } alt="" className="shape shape1 cloud1" />
                        <img src={require ("../../assets/img/shape/rmc.png") } alt="" className="shape shape2" />
                        <img src={require ("../../assets/img/shape/dotmr.png") } alt="" className="shape shape3" />
                        <img src={require ("../../assets/img/shape/plusgs.png") } alt="" className="shape shape4" />
                        <img src={require ("../../assets/img/shape/plusb.png") } alt="" className="shape shape5" />
                        <img src={require ("../../assets/img/shape/plusg.png") } alt="" className="shape shape6" />
                        <img src={require ("../../assets/img/shape/plusgs.png") } alt="" className="shape shape7" />
                        <img src={require ("../../assets/img/shape/plusr.png") } alt="" className="shape shape8" />
                        <img src={require ("../../assets/img/toothfairy.png") } alt="" className="shape shape9" />
                        <img src={require ("../../assets/img/shape/xsrdot.png") } alt="" className="shape shape10" />
                        <img src={require ("../../assets/img/shape/aab.png") } alt="" className="shape shape11 cloud2" />
                        <img src={require ("../../assets/img/shape/aab.png") } alt="" className="shape shape12 cloud3" />
                    </div>
                    <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-12 col-md-10">
                        {/* Mobile Appointment Section */}
                        <div className="hero-slide-left appointment-mobile">
                            <h2>Fethiye</h2>
                            <h1>Çocuk Diş Hekimi</h1>
                            <p>Uzman Dt. Nermin Ismayilova<br /> Modern tedavi yöntemleri, sevgi dolu, çocuk dostu bir ortam ve uzman ekibimizle her anınızı özel kılın</p>
                            <a href='tel:+902526128844' className="theme-btn fill-btn">Randevu</a>
                        </div>

                        {/* Desktop Appointment Section */}
                        <div className="hero-slide-left appointment-desktop">
                            <h2>Fethiye</h2>
                            <h1>Çocuk Diş Hekimi</h1>
                            <p>Uzman Dt. Nermin Ismayilova<br /> Modern tedavi yöntemleri, sevgi dolu, çocuk dostu bir ortam ve uzman ekibimizle her anınızı özel kılın</p>
                           <Link to="../page/contact" className="theme-btn fill-btn">Randevu</Link>
                        </div>
                    </div>
                </div>
            </div>
                </div>
            </section>
        )
    }
}

export default HeroOne
