import React from 'react';

const SpeedDial = () => {
  const toggleMenu = () => {
    const menu = document.getElementById('speed-dial-menu-default');
    menu.classList.toggle('open');

    const tooltips = document.querySelectorAll('.tooltip');
    tooltips.forEach((tooltip) => {
      tooltip.classList.toggle('open');
    });
  };

  return (
    <div data-dial-init className="fixed end-6 bottom-6 group">
      <div
        id="speed-dial-menu-default"
        className="flex flex-col items-center hidden mb-4 space-y-2"
      >
        <a
          href="https://www.instagram.com/fethiyecocukdishekimi/"
          target="_blank"
          rel="noopener noreferrer"
          data-tooltip-target="tooltip-instagram"
          data-tooltip-placement="left"
          className="flex justify-center items-center w-[52px] h-[52px] text-gray-500 hover:text-gray-900 bg-white rounded-full border border-gray-200 dark:border-gray-600 shadow-sm dark:hover:text-white dark:text-gray-400 hover:bg-gray-50 dark:bg-gray-700 dark:hover:bg-gray-600 focus:ring-4 focus:ring-gray-300 focus:outline-none dark:focus:ring-gray-400"
        >
          <img
            src={require("../assets/img/social-media/insta.png")}  
            alt="Instagram"
            className="w-12 h-12"
          />
        </a>
        <div
          id="tooltip-instagram"
          role="tooltip"
          className="absolute z-10 invisible inline-block w-auto px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
        >
          Instagram
          <div className="tooltip-arrow" data-popper-arrow></div>
        </div>
        <a
          href="https://wa.me/+905013448844"
          target="_blank"
          rel="noopener noreferrer"
          data-tooltip-target="tooltip-whatsapp"
          data-tooltip-placement="left"
          className="flex justify-center items-center w-[52px] h-[52px] text-gray-500 hover:text-gray-900 bg-white rounded-full border border-gray-200 dark:border-gray-600 shadow-sm dark:hover:text-white dark:text-gray-400 hover:bg-gray-50 dark:bg-gray-700 dark:hover:bg-gray-600 focus:ring-4 focus:ring-gray-300 focus:outline-none dark:focus:ring-gray-400"
        >
          <img
            src={require("../assets/img/social-media/wp.png")}  
            alt="WhatsApp"
            className="w-12 h-12"
          />
        </a>
        <div
          id="tooltip-whatsapp"
          role="tooltip"
          className="absolute z-10 invisible inline-block w-auto px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
        >
          WhatsApp
          <div className="tooltip-arrow" data-popper-arrow></div>
        </div>
      </div>
      <button
        type="button"
        onClick={toggleMenu}
        aria-controls="speed-dial-menu-default"
        aria-expanded="false"
        className="flex items-center justify-center text-white bg-[#fd5185] rounded-full w-14 h-14 hover:bg-[#e9225e] dark:bg-[#e9225e] dark:hover:bg-[#fd5185] focus:ring-4 focus:ring-[#fd5185] focus:outline-none dark:focus:ring-[#e9225e]"
      >
        <svg
          className="w-5 h-5 transition-transform group-hover:rotate-45"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 18 18"
        >
          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 1v16M1 9h16"/>
        </svg>
        <span className="sr-only">Open actions menu</span>
      </button>
    </div>
  );
};

export default SpeedDial;
