import React, { Component } from 'react';
import Banner from '../Banner';

class Contact extends Component {
    render() {
        return (
            <React.Fragment>
                <Banner pageTitle='İletişim' />

                <section className="contact-us-wrapper section-padding">
                    <div className="contact-information">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 col-xl-4 col-md-6 col-12">
                                    <div className="single-contact-info icon1">
                                        <div className="c-icon">
                                            <i className="fal fa-home" />
                                        </div>
                                        <div className="c-info">
                                            <h4>Adres</h4>
                                            <p>Mustafa Kemal Blv. No:34</p>
                                            <p>Tuzla, 48300 Fethiye/Muğla, Türkiye</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xl-4 col-md-6 col-12">
                                    <div className="single-contact-info icon2">
                                        <div className="c-icon">
                                            <i className="fal fa-envelope" />
                                        </div>
                                        <div className="c-info">
                                            <h4>email</h4>
                                            <p>info@fethiyecocukdishekimi.com</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xl-4 col-md-6 col-12">
                                    <div className="single-contact-info icon3">
                                        <div className="c-icon">
                                            <i className="fal fa-phone-volume" />
                                        </div>
                                        <div className="c-info">
                                            <h4>Telefon</h4>
                                            <p>0252 612 88 44</p>
                                            <p>+90 501 344 88 44</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="contact-form-wraper">
                        <div className="container map-container">
                            {/* Embed Google Maps iframe */}
                            <iframe
                                title='Fethiye Çocuk Diş Hekimi Uzman dt. Nermin Ismayilova- Pedodonti fethiye'
                                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12807.81295494285!2d29.1243693!3d36.6275122!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14c043f0fd048071%3A0xac758e6717819b4e!2sFethiye%20%C3%87ocuk%20Di%C5%9F%20Hekimi%20Uzman%20dt.%20Nermin%20Ismayilova-%20Pedodonti%20fethiye!5e0!3m2!1sen!2saz!4v1705714580829!5m2!1sen!2saz"
                                width="100%"
                                height="450"
                                style={{ border: '0' }}
                                allowFullScreen=""
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                            ></iframe>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default Contact;
