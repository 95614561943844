import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const TreatmentHero = () => {
  const serviceItem = [
    {
      id: 1,
      imgUrl: require ('../../assets/img/treatments/1.jpg'),
      title: "Diş Hekimi Muayenesi, Ağız ve Diş Sağlığını Koruma",
      text: "Altı ayda bir düzenli check up programları ile diş ve çevre dokuların detaylı değerlendirilmesi yapılıp, sorunların erken dönemde tespit edilmesi hedeflenmektedir.",
      link: ".#",
    },
    {
      id: 2,
      imgUrl: require ('../../assets/img/treatments/2.jpg'),
      title: "Non- invaziv Diş Hekimliği",
      text: "Çürük lezyonlarının henüz kavite oluşumu göstermediği aşamada non-invaziv yani girişimsel olmayan yöntemler kullanılarak bu lezyonlara müdahale edilmesi çürüğün ilerleme sürecini yavaşlatarak veya durdurarak daha fazla doku kaybının önüne geçilmesi bakımından önem taşımaktadır.",
      link: ".#",
    },
    {
      id: 3,
      imgUrl: require ('../../assets/img/treatments/3.jpg'),
      title: "Estetik Çocuk Diş Hekimliği",
      text: "Diş yüzeyindeki çürük, renklenmiş alanlar ya da kırıkların tedavisi ile gerekli fonksiyon sağlanırken, özel malzemeler ve yenilikçi uygulamalar ile estetik ihtiyaçlar da karşılanmaktadır.",
      link: ".#",
    },
    {
      id: 4,
      imgUrl: require ('../../assets/img/treatments/4.jpg'),
      title: "Flor Uygulaması",
      text: "Flor uygulaması ile çocuğunuzun dişleri asit ataklarına karşı daha dirençli hale gelir ve çürüklerden korunur.",
      link: ".#",
    },
    {
      id: 5,
      imgUrl: require ('../../assets/img/treatments/5.jpg'),
      title: "Fissur Örtücüler",
      text: "Fissür örtücüler, diş yüzeylerinin özel dolgu malzemeleri ile kaplanarak çürüklerin oluşmasını engelleyen koruyucu bir tedavidir.",
      link: ".#",
    },
    {
      id: 6,
      imgUrl: require ('../../assets/img/treatments/6.jpg'),
      title: "Koruyucu ve Önleyici Ortodontik Tedaviler",
      text: "Gelecekte oluşabilecek ortodontik problemlerin erkenden teşhis edilmesini sağlamak, diş teli tedavisine gerek kalmadan dişlerin olması gereken düzene sahip olması için gereken müdahalelerin yapılmasıdır.",
      link: ".#",
    },
    {
      id: 7,
      imgUrl: require ('../../assets/img/treatments/7.jpg'),
      title: "Yer Tutucular",
      text: "Yer tutucular erken kaybedilen süt dişinin, daimi dişler çıkana kadar mevcut olan boşluğunu koruyan sabit veya hareketli çeşitleri olan aygıtlardır. Vaktinden önce kaybedilen süt dişlerinin yerini doldurması gereken kalıcı diş için koruduğu boşluğu açık tutan ağız içi aparatlardır.",
      link: ".#",
    },
    {
      id: 8,
      imgUrl: require ('../../assets/img/treatments/8.jpg'),
      title: "Rejeneratif Tedaviler",
      text: "Çürük, enfeksiyon veya travma nedeniyle zarar gören dişlerin doğal yollar (apeksogenezis) veya ilaçlar yardımı (apeksifikasyon) ile vücudun kendi yapım mekanizmalarını uyarıp onarılmasını sağlayan tedavilerdir.",
      link: ".#",
    },
    {
      id: 9,
      imgUrl: require ('../../assets/img/treatments/9.jpg'),
      title: "Süt ve Daimi Diş Kanal Tedavileri",
      text: "Çocuk Diş Kanal Tedavisi, çürük veya travma nedeniyle pulpa dokusu zarar gören dişlerin kök kanallarının temizlenip steril hale getirilip ağızda tam fonksiyon görebilmesi için yapılan tedavilerdir.",
      link: ".#",
    },
    {
      id: 10,
      imgUrl: require ('../../assets/img/treatments/10.jpg'),
      title: "Sedasyon ve Genel Anestezi",
      text: "İleri seviyede diş hekimi korkusu ve kaygısı olan ya da engelli çocukların diş tedavileri, tek seansta sedasyon ya da genel anestezi altında konforlu şekilde yapılmaktadır.",
      link: ".#",
    },
  ];

  const settings = {
    accessibility: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className='w-3/4 m-auto'>
      <div className='mt-20'>
        <Slider {...settings}>
          {serviceItem.map((d) => (
            <div key={d.id} className='bg-[#f2f8ff] h-[550px] text-black rounded-xl flex flex-col'>
              <div className='h-56 rounded-t-xl bg-[#fd5185] flex justify-center items-center'>
                <img src={d.imgUrl} alt='Fethiye Çocuk Diş Hekimi' className='h-[200px]' />
              </div>

              <div className='flex flex-col justify-between items-center gap-4 p-4 flex-1'>
                <div>
                  <h2 className='text-xl'>{d.title}</h2>
                  <p className='text-[#5e5e5e] text-base'>{d.text}</p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default TreatmentHero;
