const FooterData = {
    singleWidget: [
       
        
        {
            id: 3,
            title: "Tedavilerimiz",
            menuItems: [
                {
                    id: 1,
                    url: ".#",
                    title: "Diş Hekimi Muayenesi, Ağız ve Diş Sağlığını Koruma Programları"
                },
                {
                    id: 2,
                    url: ".#",
                    title: "Non- invaziv Diş Hekimliği"
                },
                {
                    id: 3,
                    url: ".#",
                    title: "Estetik Çocuk Diş Hekimliği"
                },
                {
                    id: 4,
                    url: ".#",
                    title: "Flor Uygulaması"
                },
                {
                    id: 5,
                    url: ".#",
                    title: "Fissur Örtücüler"
                },
                
            ]
        },
        {
            id: 4,
            title: "Tedavilerimiz",
            menuItems: [
               
                {
                    id: 6,
                    url: ".#",
                    title: "Koruyucu ve Önleyici Ortodontik Tedaviler"
                },
                {
                    id: 7,
                    url: ".#",
                    title: "Yer Tutucular"
                },
                {
                    id: 8,
                    url: ".#",
                    title: "Rejeneratif Tedaviler"
                },
                {
                    id: 9,
                    url: ".#",
                    title: "Süt ve Daimi Diş Kanal Tedavileri"
                },
                {
                    id: 10,
                    url: ".#",
                    title: "Sedasyon ve Genel Anestezi"
                },
            ]
        }
    ],


    copyright: "2024 - Webs Design by DOSE Creative Bureau",

}


export default FooterData
