import React, { Component } from 'react'
import SpeedDial from '../SpeedDial';

export class FooterOne extends Component {
    render() {
        let FooterData = this.props.FooterData;
        return (
            <footer className="footer-wrapper footer-one">
                <SpeedDial />
                <div className="footer-widgets-wrapper section-bg text-white">
                    <div className="container">
                        <div className="row">

                            {
                                FooterData.singleWidget.map( (widget, index) => {
                                    return (
                                        index < 3 ?
                                        <div className={`col-sm-6 col-lg-3 col-12 ${widget.id > 1 ? ' offset-lg-1' : '' }`} key={widget.id} >
                                            <div className="single-footer-widget">
                                                <div className="f-widget-title">
                                                    <h2 key={widget.id}>{widget.title}</h2>
                                                </div>
                                                <div className="widegts-content">
                                                    {
                                                        widget.menuItems.map(item => {
                                                            return (
                                                                <a href={item.url} key={item.id}>{item.title}</a>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        ''
                                    )
                                })
                            }

                            <div className="col-sm-6 col-lg-3 offset-lg-1 col-12">
                                <div className="single-footer-widget">
                                    <div className="f-widget-title">
                                        <h2>İletişim</h2>
                                    </div>
                                    <div className="widegts-content">
                                        <p>Mustafa Kemal Blv. No:34
                                <br /> Tuzla, 48300 Fethiye/Muğla, Türkiye</p>
                                        <li><span>Tel:</span> 0252 612 88 44</li>
                                        <li><span>GSM:</span> +90 501 344 88 44</li>
                                        <li><span>Email:</span> info@fethiyecocukdishekimi.com</li>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-copyright-wrapper text-center">
                    <p>Tüm hakları mahfuzdur &copy; {FooterData.copyright}</p>
                </div>
            </footer>
        )
    }
}

export default FooterOne
