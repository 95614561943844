import React, { Component } from 'react'
import Banner from '../Banner';
import TreatmentHero from '../Service/TreatmentHero';
 
class ServiceDetails extends Component {
    render() {

        return (
            
                <section>
                    <Banner pageTitle='Tedavilerimiz' />
                    <div className='trnice'>
                    <TreatmentHero />
                    </div>
                </section>
        )
    }
}

export default ServiceDetails
