import React, { Component } from 'react'
import Banner from '../Banner';
import AboutTwo from '../About/AboutTwo';


class DentistDetails extends Component {
    render() {
        

        return (
            <React.Fragment>
                <Banner pageTitle='Uzman Dt. Nermin Ismayilova' />
                <AboutTwo />
            </React.Fragment>
        )
    }
}

export default DentistDetails
